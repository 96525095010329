<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import MonthlyEmployeessalary from "./Monthly Employees Salary/MonthlyEmployeessalary.vue";
// import TotalEmployeesalaey from "./Total Employee Salary/TotalEmployeesalaey.vue";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    MonthlyEmployeessalary,
    // TotalEmployeesalaey
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.payroll.subItems.payrollmanagment')"
    />
    <!-- <b-tabs   :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
      <b-tab active>
        <template v-slot:title>
          <span :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{$t("menu.menuitems.hr.subItems.emps")}}</span>
        </template> -->
    <monthly-employeessalary></monthly-employeessalary>
    <!-- end emp tab-->
    <!-- </b-tab>
      <b-tab>
        <template v-slot:title>
          <span  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{$t("popups.employeestatment")}} </span>
        </template>
        <total-employeesalaey></total-employeesalaey>
           


</b-tab>
</b-tabs> -->
  </Layout>
</template>
<style scoped></style>
